<div class="social-sharing hidden-print" [style.display]="isMobileApp?'none':'block'" #highlightElement>
    <div class="icons-container">
        <a (click)="openPopup($event)" action-tracking action-tracking-values="Icon,Click,Toggle Share Popup">
            <pb-icon size="28" name="share2"></pb-icon>
        </a>
    </div>
    

    <div class="pb-modal" *ngIf="isPopupVisible" (click)="closePopup($event)">
        <div class="modal-body">
            <a (click)="closePopup()" class="modal-close"><pb-icon size="20" name="cross"></pb-icon></a>
            <share #shareComponent [parameters]="componentModel" (onRequestClosePopup)="closePopup($event)"></share>
        </div>
    </div>
</div>
