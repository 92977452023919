import { Component, ViewChild, ElementRef, Input, ViewEncapsulation, OnInit, OnDestroy } from "@angular/core";
import { HighlightableComponent } from "../../component.base.classes/highlightable.component";
import { SharingComponentBase } from "../../component.base.classes/sharing.component.base";
import { ShareComponent } from "../share/share.component";
import { PbIconComponent } from "../../../controls/pb-icon/pb-icon.component";
import { PlatformHelpersService } from "src/app/services/platform.helpers.module/platform.helpers.service";
import { SharingService } from "src/app/services/sharing.module/sharing.service";
import { UrlHelpersService } from "src/app/services/url.helpers.module/url.helpers.service";
import { TrackingService } from "src/app/services/tracking.module/tracking.service";
import { ApplicationStateService } from "src/app/services/application.state.module/application.state.service";
import { NgIf } from "@angular/common";
import { ISubscriber } from "src/app/services/messenger.module/model/model";
import { MESSAGE_TYPES } from "src/app/infrastructure/message.types";
import { Messenger } from "src/app/services/messenger.module/messenger";

@Component({
    selector: 'social-sharing',
    templateUrl: './social-sharing.component.html',
    styleUrls: ['./social-sharing.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [PbIconComponent, ShareComponent, NgIf]
})

export class SocialSharingComponent extends SharingComponentBase implements HighlightableComponent, OnInit, OnDestroy {
    // @ViewChild('highlightElement', { static: true }) highlightViewChild: ElementRef;
    @ViewChild('shareComponent', { static: true }) shareComponent: SocialSharingComponent;
    highlightElementRef: ElementRef;

    @Input() set shareUrl(value: string) {
        this.componentModel.shareUrl = value;
    }
    get shareUrl(): string {
        return this.componentModel.shareUrl;
    }
    

    @Input() set subHeader(value: string) {
        this.componentModel.subHeader = value;
    }
    get subHeader(): string {
        return this.componentModel.subHeader;
    }
    @Input() set shareSubjectText(value: string) {
        this.componentModel.shareSubjectText = value;
    };
    get shareSubjectText(): string {
        return this.componentModel.subHeader;
    }
    @Input() set shareContentText(value: string) {
        this.componentModel.shareContentText = value;
    };
    get shareContentText(): string {
        return this.componentModel.shareContentText;
    }
    @Input() set shareSocialMediaText(value: string) {
        this.componentModel.shareSocialMediaText = value;
    };
    get shareSocialMediaText(): string {
        return this.componentModel.shareSocialMediaText;
    }

    isPopupVisible: boolean = false;

    constructor(
        platformService: PlatformHelpersService,
        shareService: SharingService,
        urlsService: UrlHelpersService,
        trackingService: TrackingService,
        private messenger: Messenger,
        applicationStateService: ApplicationStateService
        
    ) {
        super(shareService, urlsService, platformService, trackingService, applicationStateService);
    }
    ngOnDestroy(): void {
        this.messenger.Unsubscribe(this.shareClicked);
    }
    ngOnInit(): void {
        this.messenger.Subscribe(this.shareClicked);
    }

    openPopup(e: any): void{  
        this.isPopupVisible = true;
    }

    closePopup(e: any): void{
        this.isPopupVisible = false;
    }

    OnClickedSharingItem(type: string) {
        this.PerformShare(type);
    }

    private shareClicked: ISubscriber = {
        messageType: MESSAGE_TYPES.SHARE_CLICKED,
        On: () => {
            this.openPopup(null);
        }
    }

}

